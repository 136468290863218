/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~bootstrap/scss/bootstrap.scss";

* {
    box-sizing: border-box;
    font-size: 16px;
}

:root {
    --background-color: #E5E5E5;
    --dark-blue-color: #002239;
    --dark-blue-80-color: #394650CC;
    --dark-grey-color: #394650;
    --mid-grey-color: #848A8C;
    --light-grey-color: #766f6f;
    --red-color: #E30421;
    --red-50-color: #e3042166;
    --electric-yellow-color: #F2E400;
    --eco-blue-color: #18DBE6;
    --green-color: #B6CD00;
    --white-color: #FFFFFF;
    --light-blue-color:#9cd8f4;
    --light-yellow-color: #faef83;
    --orange-color: #dc4a1f;
    --light-brown-color: #b54423;
    --picton-blue-color: #4b9dec;
    --mariner-blue-color: #3282d4;
    --purple-heart-color: #792abf;
    --electric-purple-violet-color: #9523ef;

    --status-whitesmoke: #e6e6e6;
    --status-red : #f24822;
    --status-orange: #f25422;
    --status-coral : #e9a34c;
    --status-yellow : #fef418;
    --status-mint : #aff4c6;
    --status-green : #14ae5c;
    --status-sky : #bde3ff;
    --status-blue: #0d99ff;
    --status-purple : #9747ff;

    --status-border-whitesmoke: #b8b8b8;
    --status-border-red : #c23a1b;
    --status-border-orange: #c2431b;
    --status-border-coral : #ba823d;
    --status-border-yellow : #cbc313;
    --status-border-mint : #8cc39e;
    --status-border-green : #108b4a;
    --status-border-sky : #97b6cc;
    --status-border-blue: #0a7acc;
    --status-border-purple : #7939cc;
}

html {
    height: 100%;
}

body {
    margin: 0;
    border: 1px solid transparent;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: var(--background-color);
}


.float-r {
    float: right;
}
.required {
    color:var(--red-color);
    font-size: small !important;
}

// BUTTON
.nbt-btn-primary {
    font-size: 18px;
    font-weight: 600;
    padding: 8px 24px 8px 16px;
    background-color: var(--dark-blue-color);
    color: var(--white-color);
    border-radius: 12px;
    border: 2px solid var(--dark-blue-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-secondary {
    font-size: 18px;
    font-weight: 600;
    padding: 8px 24px 8px 16px;
    background-color: transparent;
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 2px solid var(--dark-blue-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-follow-up {
    font-size: 15px;
    font-weight: 400;
    padding: 10px 24px 10px 16px;
    background-color: transparent;
    color: var(--dark-blue-color);
    border-radius: 10px;
    border: 2px solid var(--dark-grey-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-follow-up:hover, .nbt-btn-follow-up:active{
    background-color: var(--dark-blue-color);
    color: var(--white-color);
}

.nbt-btn-follow-up-hello, .nbt-btn-follow-up-hello:active {
    background-color: var(--dark-blue-color) !important;
    color: var(--white-color) !important;
}

.nbt-btn-basic {
    font-size: 18px;
    font-weight: 600;
    padding: 8px 24px 8px 16px;
    background-color: transparent;
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 2px solid var(--dark-grey-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-fourth{
    font-size: 16px;
    font-weight: 500;
    padding: 4px 12px 4px 3px;
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 2px solid var(--mid-grey-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-fifth {
    font-size: 16px;
    font-weight: 450;
    padding: 8px 24px 8px 16px;
    background-color: var(--orange-color);
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 4px solid var(--light-brown-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-sixth {
    font-size: 16px;
    font-weight: 450;
    padding: 8px 24px 8px 16px;
    background-color: var(--picton-blue-color);
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 4px solid var( --mariner-blue-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbt-btn-seventh {
    font-size: 16px;
    font-weight: 450;
    padding: 8px 24px 8px 16px;
    background-color: var(--electric-purple-violet-color);
    color: var(--dark-blue-color);
    border-radius: 12px;
    border: 4px solid var(--purple-heart-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-button{
    font-size: 18px;
    font-weight: 500;
    outline: rgba(0,0,0,.42);
    border-radius: 12px;
    padding: 11px 15px 11px 15px;
    background-color: transparent;

}

.nbt-page-button{
    padding: 11px 15px 11px 15px;
    border-radius: 35px;
    background-color: transparent;
    border-color: rgba(0,0,0,.42);
}

.nbt-page-button:hover {
    background: rgba(0, 0, 0, 0.42);
}


.nbt-btn-primary:disabled,
.nbt-btn-secondary:disabled,
.nbt-btn-follow-up:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

//file-upload

::file-selector-button{
    display:none;
  }

.file-upload{
    border: 2px outset gray;
    border-radius: 5px;
    margin-bottom: 30px;
}
//file-upload-field-outline
.attachment-voc {
    width: 100%;
    height: 44px;
    border: 0.5px solid gray;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
}

//DIALOG
.nbt-dialog-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #CDD6E1;

    .nbt-dialog-title {
        font-size: 18px;
        font-weight: 600;
    }

    .nbt-dialog-close {
        border: unset;
        background: transparent;
        padding: 0;
        font-size: 24px;
    }
}

.nbt-dialog-body {
    max-height: 70vh;
    overflow: hidden auto;
    padding: 24px;
    position: relative;
    white-space: pre-line;

    .date-picker{
        margin-top: 5px;
        padding: 4px;
        border-color: rgba(0,0,0,.42);
        border-radius: 5px;
        box-shadow: none;
    }
}

.nbt-dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    border-top: 1px solid #CDD6E1;
}

.mat-dialog-xl mat-dialog-container {
    width: 1280px !important;
}

.mat-dialog-lg mat-dialog-container {
    width: 1000px !important;
}

.mat-dialog-md mat-dialog-container {
    width: 800px !important;
}

.mat-dialog-sm mat-dialog-container {
    width: 500px !important;
}

.mat-dialog-container {
    padding: 0 !important;
}

// STATUS
.nbt-status {
    font-weight: 600;
    border-radius: 14px;
    width: max-content;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 14px !important;
    height: fit-content !important;
    white-space: nowrap;
    color: var(--white-color);
}

.status-eco-blue {
    background-color: var(--eco-blue-color);
}

.status-mid-grey {
    background-color: var(--mid-grey-color);
}

.status-color-new {
    background-color: #AFF4C6;
    color: black;
}

.status-color-assigned {
    background-color: #E6E6E6;
    color: black;
}

.status-color-open {
    background-color: #BDE3FF;
    color: black;
}

.status-color-onhold {
    background-color: #FEF418;
    color: black;
}

.status-color-inprogress {
    background-color: #0D99FF;
}

.status-color-cancel {
    background-color: #B3B3B3;
}

.status-color-resolved {
    background-color: #9747FF;
}

.status-color-reopen {
    background-color: #E9A34C;
    color: black;
}

.status-color-closed {
    background-color: #14AE5C;
}

.status-color-rescheduled {
    background-color: #9747FF;
    color: black;
}

.status-color-completed {
    background-color: #c44ce9;
    color: white;
}

.status-color-declined {
    background-color: #e94c4c;
    color: black;
}



// MAT TABLE
table {
    width: 100%;
}

.mat-table {
    background-color: transparent !important;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5em;
}

table tr:last-child td {
    border-bottom: 0 solid
}

.mat-card-content-table {
    border-radius: 12px;
    border: 1px solid #CDD6E1;
    background: white;
    overflow: auto;
    display: block;
    margin-bottom: 16px;

    tr.mat-header-row {
        height: 40px !important;
        background: #00223999;
    }

    .mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow {
        margin: 0 0 0 6px;
        color: white;
    }
}

.mat-sort-header-container {
    color: #404040;
    font-weight: 800;
    min-width: 140px;

    .mat-sort-header-content {
        font-size: 12px !important;
        color: white;
    }
}

.mat-column-surveyHeader {
    .mat-sort-header-content {
        width: 100%;
        display: block;
    }
}

.mat-column-isActiveHeader {
    .mat-sort-header-content {
        width: 100%;
        display: block;
    }
}

.mat-column-active,
.mat-column-actions {
    .mat-sort-header-container {
        min-width: unset !important;
    }
}

th.mat-header-cell
{
    padding: 14px !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: white;
    font-weight: 800;
    min-width: 170px;
}

td.mat-cell,
td.mat-footer-cell {
    padding: 14px !important;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    mat-icon {
        font-size: 20px;
        color: var(--dark-blue-color);
        cursor: pointer;
        margin-right: 8px;
    }
}

.mat-column-actions {
    white-space: nowrap;
}

.mat-row:nth-child(2n+1) {
    background-color: #F6F8FA;
}

.mat-row:not(:nth-child(2n+1)) {
    background-color: var(--white-color);
}

.mat-row-light-grey{
    background-color: #DADADA  !important;
}

.mat-row-mid-grey{
    background-color: #b4b4b4 !important;
}


// ANGULAR CALENDAR
mwl-calendar-month-view {
    .cal-month-view {
        border-radius: 12px !important;
        min-width: 1000px;

        .cal-header {
            background-color: #00223999;
            color: white;
            border-radius: 12px 12px 0 0;

            .cal-cell:hover {
                background: #00223999;
            }
        }

        .cal-header:hover {
            background: #00223999;
        }
    }
}

.bg-red {
    background-color: var(--red-color) !important;
    cursor: pointer;
}

.bg-electric-yellow {
    background-color: var(--electric-yellow-color) !important;
    cursor: pointer;
}

.bg-light-yellow{
    background-color: var(--light-yellow-color) !important;
    cursor: pointer;
}

.bg-light-blue{
    background-color: var(--light-blue-color) !important;
    cursor: pointer;
}

.disabled {
    background-color: #eaeaea54 !important;
    cursor: no-drop;
}

.mat-tab-body-content {
    overflow: unset !important;
}

//LOADING ANIMATION
.nbt-data-loading {
    border: 15px solid var(--mid-grey-color);
    border-radius: 90%;
    border-top: 15px solid var(--dark-blue-color);
    width: 180px;
    height: 180px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.nbt-empty-result {
    height: 400px;
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

// @See https://stackoverflow.com/a/46699595/13841498
/* Absolute Center Spinner */
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

@media only screen and (max-width: 600px) {
    .nbt-empty-result {
        height: 100px;
        min-width: unset !important;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
// OTHERS
.app-component-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}

.app-component-header {
    flex-direction: row;
    justify-content: center;
    flex: 1 1 60%;
}

.app-component-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.app-component-content>* {
    margin: 0.3em;
}

.nbt-form {
    display: flex;
    flex-direction: column;

    .mat-form-field-label-wrapper>label {
        transform: unset !important;
        width: unset !important;
    }

    .mat-form-field-infix {
        height: 44px;
        padding: 0 !important;
        align-items: center;
        margin-top: 5px;
        border-top: unset;
        display: flex !important;
    }

    .mat-form-field-infix input {
        height: 100%;
        padding: 0 0.75rem !important;
        box-sizing: border-box !important;
        border: -0.5px solid #CDD6E1;
        border-radius: 4px;
    }

    .mat-form-field-infix input:disabled,
    .mat-form-field-appearance-outline .mat-form-field-flex:disabled  {
        background-color: #e9ecef;
        cursor: not-allowed;
    }

    .mat-form-field-infix input:visited {
        background-color: red !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-flex {
        padding: 0 !important;
    }

    .mat-form-field.mat-warn .mat-input-element,
    .mat-form-field-invalid .mat-input-element {
        caret-color: #f44336;
        border: 1px solid #f44336 !important;
        border-radius: 4px;
    }

    .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
        padding: 0 !important;
    }

    mat-error {
        font-size: 12px;

        span {
            font-size: 12px;
        }
    }

    .mat-checkbox {
        .mat-checkbox-inner-container {
            height: 20px;
            width: 20px;
        }
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: var(--dark-blue-color) !important;
        border-radius: 4px;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: var(--dark-blue-color) !important;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: var(--dark-blue-color) !important;
    }

    input:invalid {
        border: 2px solid red !important;
    }

    .material-symbols-text{
        margin: 6px;
    }

    .custom-info{
        position: absolute;
        margin: 33px 0px 0px 26px;
    }

}

.nbt-form-textarea {

    .mat-form-field {
        width: 100% !important;
    }
    .mat-form-field-infix {
        border-top: 0px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
        padding: 0 !important;
    }
    mat-error {
        font-size: 12px !important;
    }
    .material-symbols-textarea{
        margin-top: 6px;
        float: right;
    }
}

.nbt-search-bar {
    .mat-form-field-infix input {
        border-radius: 12px !important;
        background-color: var(--white-color);
    }

    .mat-form-field-infix {
        position: relative;

        mat-icon {
            position: absolute;
            cursor: pointer;
        }

        .close {
            right: 30px;
        }

        .search {
            right: 2px;
        }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border: transparent !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border: transparent !important;
    }
}

.mat-paginator {
    border-radius: 12px;
}

.relative {
    position: relative;
}

.forgot-password-link {
    margin-top: 24px;
    color: var(--dark-blue-color);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.mt8 {
    margin-top: 8px !important;
}

.mr8 {
    margin-right: 8px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

.ml8 {
    margin-left: 8px !important;
}

.login-card {
    width: 100%;
    max-width: 480px;
    box-shadow: 0px 4px 12px #40404014 !important;
    padding: 40px !important;
    border-radius: 12px !important;
}

.view-password {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

.login-section {
    margin-bottom: unset !important;
}

.login-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-img-login {
    padding: 50px;
}

.forgot-password-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.app-toolbar {
    flex-direction: row;
    height: 80px;
    background-color: var(--white-color);
    border-bottom: 2px solid var(--picton-blue-color);
    justify-content: space-between;

    img {
        height: 50px;
    }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 0 28px !important;
}

.right-toolbar {
    display: flex;
    align-items: center;

    .username {
        font-size: 18px;
        font-weight: 600;
        margin: 16px;
    }

    .profile {
        font-size: 40px;
        width: 40px;
        color: var(--picton-blue-color);
    }

    .bell {
        font-size: 40px;
        width: 40px;
        color: var(--picton-blue-color);
    }
}
div.mat-menu-panel {
    min-width: 100px;
      /** set width to 500px */
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    border-radius: 4px;
    outline: 0;
}

.mat-menu-content:not(:empty) {
    padding-bottom: 0 !important;
}

button.mat-menu-item-custom {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    /** set line height to desired height */
    line-height: normal;
    /** set height to auto */
    height: auto;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
}

.select-institution {
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0 !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }
}

mat-select {
    padding: 0 16px;
}

.app-container {
    .left-section {
        float: left;
        width: 72px;
        position: fixed;
        overflow: auto;
        height: 100%;
        background-color: var(--dark-blue-color);
        box-shadow: 0px 4px 12px #00000014;
        margin-top: -16px;
        margin-left: -16px;
        left: 16px;
    }
}

.mat-nav-list-close {
    a:hover {
        background-color: var(--red-color) !important;

        mat-icon {
            color: var(--white-color) !important;
        }
    }

    .mat-list-item-content {
        justify-content: center;
        margin-left: unset !important;

        mat-icon {
            font-size: 30px;
            height: 30px;
            width: 30px;
        }
    }
}

.mat-nav-list {
    a:hover {
        background-color: var(--white-color) !important;

        mat-icon {
            color: var(--dark-blue-color) !important;
        }

        span {
            color: var(--dark-blue-color) !important;
        }
    }

    mat-icon {
        font-size: 30px;
        height: 30px;
        width: 30px;
        color: var(--white-color);
    }

    span {
        font-size: 18px;
        font-weight: 600px;
        margin-left: 12px;
        color: var(--white-color);
    }
}

.burger-menu {
    font-size: 40px;
    width: 40px !important;
    color: var(--red-color);
}

.select-institution-sidebar {
    display: flex !important;
    justify-content: center;
    margin-top: 20px;
}

.select-institution-sidebar > div {
    width: 230px;
}

.nbt-container {
    margin: 24px 40px;
    width: unset;
}

.nbt-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nbt-header-title-category {
    font-size: 18px;
    font-weight: 400;
}


.nbt-header-title {
    font-size: 32px;
    font-weight: 700;
}

.nbt-btn-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nbt-search-section {
    background-color: #d6d6d6;
    padding: 24px;
    border-radius: 4px;
    border: 0.5px solid black;

    input {
        background-color: var(--white-color) !important;
    }
}

.nbt-sidenav {
    visibility: visible !important;
    width: 250px !important;
    overflow: unset !important;
    position: fixed !important;
    // height: calc(100% - 61px);
    top: 75px !important;
    background: var(--dark-blue-color);
    left: 0;

    .menu-inside {
        position: absolute;
        background-color: #fff;
        border: 0.5px solid #cdd6e1;
        border-radius: 12px;
        z-index: 100;
        white-space: nowrap;
        margin-top: -50px;
        color: #626262;
        font-size: 16px;
        width: 320px;
        background-color: var(--dark-blue-color);
        padding: 12px 4px;
    }
}

.nbt-paginator {
    .mat-paginator-range-label {
        margin: 0 24px;
        font-size: 14px;
    }

    .mat-paginator-page-size-label {
        margin: 0 4px;
        font-size: 14px;
    }

    .mat-paginator, .mat-paginator-page-size .mat-select-trigger {
        width: 40px;
    }

    .mat-select-min-line {
        font-size: 14px;
    }
}

.nbt-details {
    label {
        font-size: 14px;
        font-weight: 700;
        color: #404040;
    }

    .value {
        margin-bottom: 24px;
        word-break: break-all;
    }
}

.sla-table {
    border: 1px solid gray;
    th {
        height: 40px !important;
        background-color: #00223999;

        div {
            color: white;
        }
    }

    tbody {
        .div-head {
            display: flex;
            padding: 16px;
            align-items: center;

            .priority-dot {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin-right: 8px;
            }
        }
        tr {
            border-bottom: 0.5px solid #CDD6E1;

            .input-time-sla {
                display: flex; text-align: center;
                display: flex;
                height: 120px;
                text-align: center;
                align-items: center;
                margin-right: 16px;

                input {
                    width: 80px;
                    height: 44px;
                    padding: 0 12px;
                    border-radius: 4px;
                    border: 0.5px solid #CDD6E1;
                }
            }
        }
    }
}

.customer-record-table {
    border: 1px solid gray;
    th {
        height: 40px !important;
        background-color: #00223999;

        div {
            color: white;
        }
    }

    tbody {
        tr {
            border-bottom: 0.5px solid #CDD6E1;
            div {
                min-height: 50px;
                display: flex;
                align-items: center;

                mat-icon {
                    font-size: 20px;
                    color: var(--dark-blue-color);
                    cursor: pointer;
                    margin-right: 8px;
                }
            }
        }
    }
}

.mat-tab-body.mat-tab-body-active {
    height: 100%;
}

.nbt-select-all{
    padding: 0 16px;
}

.nbt-select-all .mat-checkbox-inner-container{
    height: 20px !important;
    width: 20px !important;
}

.nbt-select-all .mat-checkbox{
    height: 3em;
}

.nbt-select-all .mat-checkbox-layout{
    height: 100%;
    align-items: center;
}


.mat-tab-body.mat-tab-body-active {
    height: 100%;
}

.nbt-select-all .mat-checkbox-checked .mat-ripple-element,
.nbt-select-all .mat-checkbox-checked .mat-checkbox-background,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: var(--dark-blue-color) !important;
    width: 20px !important;
    height: 20px !important;
}

.mat-pseudo-checkbox-checked::after {
    top: 4px !important;
    left: 3px !important;
    width: 11px !important;
    height: 3.5px !important;
    border-left: 2px solid currentColor !important;
    transform: rotate(-45deg) !important;
    opacity: 1 !important;
    box-sizing: content-box !important;
}

.mat-pseudo-checkbox {
    width: 20px !important;
    height: 20px !important;
}


.mat-tooltip {
    position: relative;
    left: -33%;
    top:20px;
    font-size: 12px;
}

.mat-slider-track-fill,
.mat-slider-thumb{
    background-color: var(--dark-blue-color) !important;
}

// LEFT LIST IN LISTING
.left-list-detail {

    .list-section {
        width: 300px;

        .nbt-details {
            border: 3px solid gray;
            border-radius: 8px;
            padding: 15px;

            .detail-mobile {
                display: block;

                .name {
                    font-size: 20px;
                    font-weight: 600;
                    border-bottom: 3px solid gray;
                    padding-bottom: 8px;
                }
            }

            .mat-pseudo-checkbox.ng-star-inserted {
                display: none;
            }
        }

        .name-mobile {
            display: none;
        }
    }

    .left-detail-section {
        border: 3px solid gray;
        border-radius: 8px;
        margin-right: 12px;
        overflow: auto;
    }

}


// MEDIA QUERIES
@media screen and (max-width: 700px) {
    .nbt-header {
        align-items: stretch;
        flex-direction: column;
    }

    .nbt-btn-right {
        margin-top: 20px;
    }
}

@media screen and (max-width: 480px) {
    .logo-img-login {
        padding: 20px;
    }
}




@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

@media screen and (max-width: 810px) {
    .nbt-form {
        .custom-info{
            position: absolute;
            margin: 33px 0px 0px -2px;
        }
    }
}

@media screen and (max-width: 768px), screen and (max-height: 800px) {
    .nbt-sidenav {
        width: 350px !important;

        .menu-inside {
            position: unset;
            margin-top: 0px;
            margin-left: 10px;
            border: none;
        }
    }

    .nbt-data-loading {
        border: 5px solid var(--mid-grey-color);
        border-radius: 80%;
        border-top: 5px solid var(--dark-blue-color);
        width: 90px;
        height: 90px;
    }
}

.form-title {
    margin-left: 1px !important;
    line-height: 24px;
    font-size: 25px
}

@media screen and (max-width: 426px) {
    .nbt-header-title {
        font-size: 24px;
    }

    .form-title {
        font-size: 20px;
    }
}

mat-expansion-panel-header {
    border-bottom: 1px solid;

    mat-panel-title {
        font-size: 16px;
        font-weight: 600
    }
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    background: #e9ecef !important;
}

.load-more {
    text-align: center;
    background-color: lightgray;
    padding-top: 9px;
    padding-bottom: 9px;
}

.label-flex{
    display: flex;
    padding-bottom: 1.34375em;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 1024px) {
    .label-flex{
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
//@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

// ---------------------------------------------------------------------------------


